<template>
  <div class="container page-content">
    <div :style="`${heroSlot && !hero ? 'padding-top: 50px' : ''}`">
      <slot name="title" class="page-content__title" />
    </div>
    <div class="page-content__wrapper">
      <div class="container page-content__responsive-divs">
        <div v-if="sidebar" class="page-content__responsive-divs__fixed-div">
          <LazyPageSidebar
            :table-of-content="sidebarTableOfContent"
            :table-of-content-first-item-label="sidebarTableOfContentFirstItemLabel"
            :header-sub-navigation="headerSubNavigation"
          >
            <template #content>
              <slot name="sidebar" />
            </template>
          </LazyPageSidebar>
        </div>
        <div :class="`${sidebar ? 'page-content__responsive-divs__flexible-div' : 'col-12'}`">
          <slot name="content" />
        </div>
      </div>
    </div>
    <div class="page-content__break-component">
      <div class="col-md-12">
        <slot name="break" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const router = useRouter()
const props = defineProps<{
  sidebar?: boolean
  sidebarTableOfContent?: boolean
  sidebarTableOfContentFirstItemLabel?: string
  headerSubNavigation?: boolean
  heroSlot?: boolean
  hero?: boolean
}>()

const updateSubNav = () => {
  if (props.headerSubNavigation) {
    const tableOfContents = document
      .getElementsByClassName('page-sidebar__table-of-contents')
      .item(0)
    if (!tableOfContents) {
      const slider = document.getElementsByClassName('global-header__slider').item(0)
      ;(slider as HTMLElement)?.style.setProperty('display', 'none')
      const sliderControls = document
        .getElementsByClassName('global-header__slider-controls')
        .item(0)
      ;(sliderControls as HTMLElement)?.style.setProperty('display', 'none')
    }
    return
  }
}

router.afterEach(() => {
  updateSubNav()
})

const adjustFluidImagesInsideRichtext = () => {
  const elements = document.querySelectorAll('.rich-text-content .img-responsive')
  elements.forEach((element: any) => {
    element.style.width = `${element.parentElement.parentElement.offsetWidth}px`
  })
}

onMounted(() => {
  updateSubNav()
  adjustFluidImagesInsideRichtext()
})
</script>
